import { logChatPromiseExecution } from "stream-chat";
import {
  MessageInput,
  MessageInputProps,
  MessageList,
  StreamMessage,
  Thread,
  useChannelActionContext,
  Window,
} from "stream-chat-react";
import { VideoCallButton } from "../../video/videocallButton";
import { StreamChatGenerics } from "../../types";
import "./GlobalStyling.css";
import { ParticipantsButton } from "./participantsButton";
import { CaseLoadChatConversationDetails } from "../../../../../types";

type ChannelInnerProps = {
  chatDetails: CaseLoadChatConversationDetails;
  isFetchingChatDetails: boolean;
  toggleMobile: () => void;
  defaultMessage: StreamMessage<StreamChatGenerics> | undefined;
};

const ChannelInner = (props: ChannelInnerProps) => {
  const { defaultMessage } = props;
  const { sendMessage } = useChannelActionContext<StreamChatGenerics>();

  const overrideSubmitHandler: MessageInputProps<StreamChatGenerics>["overrideSubmitHandler"] =
    (message, _, ...restSendParams) => {
      let updatedMessage;

      if (message.attachments?.length && message.text?.startsWith("/giphy")) {
        const updatedText = message.text.replace("/giphy", "");
        updatedMessage = { ...message, text: updatedText };
      }

      if (sendMessage) {
        const newMessage = updatedMessage || message;
        const parentMessage = newMessage.parent;

        const messageToSend = {
          ...newMessage,
          parent: parentMessage
            ? {
                ...parentMessage,
                created_at: parentMessage.created_at?.toString(),
                pinned_at: parentMessage.pinned_at?.toString(),
                updated_at: parentMessage.updated_at?.toString(),
              }
            : undefined,
        };

        const sendMessagePromise = sendMessage(
          // @ts-expect-error todo: fix type
          messageToSend,
          ...restSendParams
        );
        logChatPromiseExecution(sendMessagePromise, "send message");
      }
    };

  const actions = [
    "delete",
    "edit",
    "flag",
    "markUnread",
    "mute",
    "react",
    "reply",
  ];

  return (
    <div className="-mt-6 flex flex-col items-center justify-center w-full max-h-[95vh] text-foreground">
      <Window>
        <div className="flex items-center justify-end w-full pb-2 space-x-2">
          <ParticipantsButton
            isFetchingChatDetails={props.isFetchingChatDetails}
            chatDetails={props.chatDetails}
          />
          <VideoCallButton
            chatChannelId={props.chatDetails.details!.channelId}
          />
        </div>

        <MessageList />
        <MessageInput
          message={defaultMessage}
          focus
          overrideSubmitHandler={overrideSubmitHandler}
          // audioRecordingConfig={{ transcoderConfig: { encoder: encodeToMp3 } }}
          audioRecordingEnabled
          asyncMessagesMultiSendEnabled
        />
      </Window>
    </div>
  );
};

export default ChannelInner;
