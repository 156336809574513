import { HelpCircle } from "lucide-react";
import { ReferralDetails } from "../../types";
import { getSimpleFormattedDateTime } from "../../../caseLoadCockpit/utils";
import { ScheduleIntakeButton } from "./scheduleIntakeButton";
import { Location } from "../fields/location";
import { DossierType } from "../fields/dossierType";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import { Separator } from "../../../../components/ui/separator";
import { CancelIntakeButton } from "./cancelIntakeButton";
import { AppointmentCreationFailure } from "./appointmentCreationFailure";
import { CrmPatientEntryPatientRegistrationStepTracker } from "../registrationStatusTracker/crmPatientEntryPatientRegistrationStepTracker";

const title = "Schedule intake";

export const ScheduleIntakeRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  // has intake, but we can still modify it
  if (
    referral.intakeOn &&
    (referral.status === "AwaitingIWelcomeEmailSend" ||
      referral.status === "AwaitingPatientApproval" ||
      referral.status === "Closed" ||
      referral.status === "RegisterPatientAndIntake")
  ) {
    return <RescheduleIntake referral={referral} stepNumber={stepNumber} />;
  }

  return <ScheduleFirstIntake referral={referral} stepNumber={stepNumber} />;
};

const ScheduleFirstIntake = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  return (
    <div>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <CrmPatientEntryPatientRegistrationStepTracker
            referralId={referral.id}
            step="schedule-intake"
          />
          <p className="text-lg font-medium leading-none">
            {stepNumber}. {title}
          </p>
        </div>

        <div className="flex items-center justify-between w-fit">
          <TooltipProvider delayDuration={100}>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle className="h-4 w-4" />
              </TooltipTrigger>
              <TooltipContent
                style={{ zIndex: 99999 }}
                className="max-w-[24rem]"
              >
                <p>
                  Please schedule an intake with a consultation room
                  psychologist (SKP), after specifying the dossier type and
                  intake location. This psychologist will be assigned to the
                  patient in Praktijkdata afterwards.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <Separator className="my-1" />

      <div className="w-full">
        <div className="pt-2 flex justify-between items-end space-x-2">
          <div className="w-2/3">
            <DossierType
              isReadOnly={false}
              referralId={referral.id}
              value={referral.dossierType?.id}
              referralStatus={referral.status}
            />
          </div>
          <div className="w-1/3">
            <ScheduleIntakeButton
              referral={referral}
              enabled={
                referral.dossierType != null && referral.location != null
              }
            />
          </div>
        </div>
      </div>

      {referral.intakeCouldNotBeScheduledInPraktijkData && (
        <AppointmentCreationFailure referral={referral} area="intake" />
      )}
    </div>
  );
};

const RescheduleIntake = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <CrmPatientEntryPatientRegistrationStepTracker
          referralId={referral.id}
          step="schedule-intake"
        />
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
      </div>

      <Separator className="my-1" />

      <div className="w-full">
        <p className="text-xs">
          An intake has been scheduled for{" "}
          <span className="font-bold">
            {getSimpleFormattedDateTime(referral.intakeOn!)} with{" "}
            {referral.intakeByPsychologistName}
          </span>
          .
        </p>

        {[
          "RegisterPatientAndIntake",
          "AwaitingPatientApproval",
          "AwaitingIWelcomeEmailSend",
        ].includes(referral.status) && (
          <>
            <div className="pt-2 flex justify-between items-end space-x-2">
              <div className="w-1/2">
                <DossierType
                  isReadOnly={false}
                  referralId={referral.id}
                  value={referral.dossierType?.id}
                  referralStatus={referral.status}
                />
              </div>
              <div className="w-1/2">
                <Location
                  isReadOnly={false}
                  referralId={referral.id}
                  value={referral.location}
                  referralStatus={referral.status}
                />
              </div>
            </div>

            <div className="pt-2 flex items-center space-x-2">
              <ScheduleIntakeButton
                referral={referral}
                enabled={
                  referral.dossierType != null && referral.location != null
                }
              />
              {referral.intakeOn && (
                <CancelIntakeButton
                  referralId={referral.id}
                  isIntakeInPraktijkData={Boolean(
                    referral.intakePraktijkDataEpdId
                  )}
                />
              )}
            </div>
          </>
        )}
      </div>

      {referral.intakeCouldNotBeScheduledInPraktijkData && (
        <AppointmentCreationFailure referral={referral} area="intake" />
      )}
    </div>
  );
};
