import axios from "axios";
import { useMutation, useQuery } from "react-query";
import {
  ReferredPatient,
  ReferralTableSortingColumns,
  ReferralDetails,
  Note,
  ActivityLog,
  PersonalInformation,
  ManualReferral,
  EpdLocation,
  EpdEmployee,
  HealthcareInsurer,
  RejectionReasonClient,
  RejectionReasonIPractice,
  EpdDossierType,
  CaseLoadProgressWithOccupancyForecast,
  HealthcareInsurerContractFilter,
  ViewType,
  GetWeeklyPatientReferralStatisticsQueryResponse,
  UnsuccessfulyReachOutAttemptEmail,
  TransferReferralFilter,
  UploadReferralLetterForm,
  PreIntakeReportTeamAndLocationFilter,
} from "./types";
import { queryClient } from "../../react-query/provider";
import { Pagination } from "../../shared/pagination";

export const refetchReferralEntries = () => {
  const queryKey = ["referral-manager", "referral"];

  queryClient.invalidateQueries(queryKey);
  queryClient.refetchQueries(queryKey);
};

export const refetchReferralDetails = () => {
  const queryKey1 = ["referral-manager", "view-details"];
  const queryKey2 = ["referral-manager", "activity-log"];

  queryClient.invalidateQueries(queryKey1);
  queryClient.refetchQueries(queryKey1);

  queryClient.invalidateQueries(queryKey2);
  queryClient.refetchQueries(queryKey2);
};

export const useGetReferredPatients = (
  locationIds: string[],
  registrationStatuses: string[],
  nextSteps: string[],
  healthcareInsurerConcerns: string[],
  transferFilter: TransferReferralFilter,
  healthcareInsurerContractFilter: string,
  viewType: ViewType,
  pageSize: number,
  skip: number,
  searchTerm: string,
  columnToSortOn: ReferralTableSortingColumns,
  orderByAsc: boolean
) => {
  const cacheInMinutes = 1000 * 60 * 1;

  const query = useQuery<{
    data: ReferredPatient[];
    pagination: Pagination;
  }>(
    [
      "referral-manager",
      "referral",
      pageSize,
      skip,
      searchTerm,
      locationIds,
      healthcareInsurerConcerns,
      registrationStatuses,
      nextSteps,
      healthcareInsurerContractFilter,
      transferFilter,
      viewType,
      columnToSortOn,
      orderByAsc,
    ],
    async () => {
      let url = new URL(
        `${window.location.protocol}//${window.location.host}/api/customerrelationshipmanager/referral`
      );

      locationIds.forEach((loc) => {
        url.searchParams.append("locationIds", loc);
      });

      healthcareInsurerConcerns.forEach((insurer) => {
        url.searchParams.append("healthCareInsurerConcerns", insurer);
      });

      registrationStatuses.forEach((status) => {
        url.searchParams.append("registrationStatuses", status);
      });

      nextSteps.forEach((nextStep) => {
        url.searchParams.append("nextSteps", nextStep);
      });

      url.searchParams.append(
        "viewType",
        {
          "working-view-with-waiting-list": "0",
          "working-view-without-waiting-list": "1",
          "calendly-view": "2",
          "full-view": "3",
          "dagco-view": "4",
        }[viewType]
      );
      url.searchParams.append(
        "healthcareInsurerContractFilter",
        healthcareInsurerContractFilter
      );
      url.searchParams.append("transferReferralFilter", transferFilter);
      url.searchParams.append("pageSize", pageSize.toString());
      url.searchParams.append("skip", skip.toString());
      url.searchParams.append("searchTerm", searchTerm);
      url.searchParams.append("columnToSortOn", columnToSortOn.toString());
      url.searchParams.append("orderByAsc", orderByAsc.toString());

      const res = await axios.get(url.toString());

      return res.data;
    },
    {
      cacheTime: cacheInMinutes,
      refetchInterval: cacheInMinutes,
    }
  );

  return query;
};

export const useAddReferralManually = (onSuccess: (id: string) => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      manualReferralDetails,
    }: {
      manualReferralDetails: ManualReferral;
    }) => {
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      const res = await axios.post(
        `customerrelationshipmanager/referral/manual-referral`,
        manualReferralDetails,
        config
      );
      return res.data;
    },
    onSuccess: (x) => {
      refetchReferralEntries();
      onSuccess(x.id);
    },
  });

  return mutation;
};

export const useUploadReferralLetter = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      referralLetter,
      documentType,
    }: {
      referralId: string;
      referralLetter: File;
      documentType: string;
    }) => {
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", referralLetter);
      formData.append("documentType", documentType);

      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/referral-letter`,
        formData,
        config
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
  });

  return mutation;
};

export const useGetIsPatientDuplicate = (email: string, bsn: string) => {
  const query = useQuery<boolean>(
    ["referral-manager", "is-dupe", email, bsn],
    async () => {
      const res = await axios.get(
        `customerrelationshipmanager/referral/is-duplicate?email=${email}&bsn=${bsn}`
      );
      return res.data.isDuplicate;
    },
    {
      enabled: email?.length > 2 || bsn?.length > 2,
    }
  );

  return query;
};

export const useGetActivityLogForReferral = (referralId: string) => {
  const query = useQuery<ActivityLog[]>(
    ["referral-manager", "activity-log", referralId],
    async () => {
      const res = await axios.get(
        `customerrelationshipmanager/referral/${referralId}/activity-log`
      );
      return res.data;
    }
  );

  return query;
};

export const useGetReferralLetter = (
  referralId: string,
  documentId: string,
  isEnabled: boolean
) => {
  const query = useQuery<string>(
    ["referral-manager", "referral-letter", referralId],
    async () => {
      const res = await axios.get(
        `customerrelationshipmanager/referral/${referralId}/referral-letter?documentId=${documentId}`,
        { responseType: "blob" }
      );
      const fileURL = URL.createObjectURL(res.data);
      return fileURL;
    },
    {
      enabled: isEnabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useSendCalendlyInvite = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ referralId }: { referralId: string }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/send-calendly-invite`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
  });

  return mutation;
};

export const useRegisterReferralAsPatient = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ referralId }: { referralId: string }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/register-as-patient`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
  });

  return mutation;
};

export const useConfirmPraktijkDataPatientApproval = (
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({ referralId }: { referralId: string }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/confirm-praktijkdata-patient-approval-without-patient-id`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchReferralEntries();
      refetchReferralDetails();
    },
    onError: () => {
      onError();
    },
  });

  return mutation;
};

export const useManuallySyncPraktijkDataPatientApproval = (
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      epdPatientId,
    }: {
      referralId: string;
      epdPatientId: number;
    }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/confirm-praktijkdata-patient-approval-with-patient-id`,
        {
          epdPatientId,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchReferralEntries();
      refetchReferralDetails();
    },
    onError: () => {
      onError();
    },
  });

  return mutation;
};

export const useScheduleIntake = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      selectedPsychologistEpdId,
      appointmentOn,
    }: {
      referralId: string;
      selectedPsychologistEpdId: number;
      appointmentOn: Date;
    }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/schedule-intake`,
        {
          selectedPsychologistEpdId,
          appointmentOn,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
  });
  return mutation;
};

export const useCancelIntake = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ referralId }: { referralId: string }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/cancel-intake`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
  });

  return mutation;
};

export const useSchedulePreIntake = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      psychologistEpdId,
      appointmentOn,
    }: {
      referralId: string;
      psychologistEpdId: number;
      appointmentOn: Date;
    }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/schedule-pre-intake`,
        {
          psychologistEpdId,
          appointmentOn,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
  });

  return mutation;
};

export const useCancelPreIntake = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ referralId }: { referralId: string }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/cancel-pre-intake`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
  });

  return mutation;
};

export const useRetrySchedulePraktijkdataAppointments = (
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({ referralId }: { referralId: string }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/retry-schedule-praktijkdata-appointments`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });

  return mutation;
};

export const useSavePersonalInfo = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      personalInformation,
    }: {
      referralId: string;
      personalInformation: PersonalInformation;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/personal-info`,
        personalInformation
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();

      onSuccess();
    },
  });

  return mutation;
};

export const useUpdateNextStep = () => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      nextStep,
    }: {
      referralId: string;
      nextStep: string;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/next-step`,
        {
          nextStep,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useUpdateTodaysManualStatus = () => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      value,
    }: {
      referralId: string;
      value: string | null;
    }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/set-daily-status`,
        {
          value,
        }
      );
      return res.data;
    },
  });

  return mutation;
};

export const useUpdateRelevance = () => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      relevance,
    }: {
      referralId: string;
      relevance: string;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/relevance`,
        {
          relevance,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useUpdateLanguage = () => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      language,
    }: {
      referralId: string;
      language: string;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/language`,
        {
          language,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useUpdateHealthcareInsurer = () => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      healthcareInsurerId,
    }: {
      referralId: string;
      healthcareInsurerId: string;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/healthcare-insurer`,
        {
          healthcareInsurerId,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useUpdateDossierType = () => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      dossierTypeId,
    }: {
      referralId: string;
      dossierTypeId: string;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/dossier-type`,
        {
          dossierTypeId,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useUpdateLocation = () => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      locationId,
    }: {
      referralId: string;
      locationId: string | undefined;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/location`,
        {
          locationId,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useUpdateHowDidThePatientHearAboutUs = () => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      howDoYouHearAboutUs,
    }: {
      referralId: string;
      howDoYouHearAboutUs: string | null;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/how-did-patient-hear-about-us`,
        {
          howDoYouHearAboutUs,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useArchiveReferral = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ referralId }: { referralId: string }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/archive`,
        {
          referralId,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });
  return mutation;
};

export const useRejectReferral = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      isRejectedByClient,
      rejectionReasonClient,
      rejectionReasonIPractice,
      justification,
      sendRejectionEmail,
      sendFullyCustomEmailTemplate,
      userInput,
    }: {
      referralId: string;
      isRejectedByClient: boolean;
      rejectionReasonClient: RejectionReasonClient | undefined;
      rejectionReasonIPractice: RejectionReasonIPractice | undefined;
      justification: string | undefined;
      sendRejectionEmail: boolean;
      sendFullyCustomEmailTemplate: boolean;
      userInput: string | undefined;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/reject`,
        {
          referralId,
          isRejectedByClient,
          rejectionReasonClient,
          rejectionReasonIPractice,
          justification,
          sendRejectionEmail,
          sendFullyCustomEmailTemplate,
          userInput,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useUndoRejection = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ referralId }: { referralId: string }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/undo-reject`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useSendForgottenRejectionEmail = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      sendFullyCustomEmailTemplate,
      userInput,
    }: {
      referralId: string;
      sendFullyCustomEmailTemplate: boolean;
      userInput: string | undefined;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/send-rejection-email`,
        {
          sendFullyCustomEmailTemplate,
          userInput,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useMarkAsOkayToReachOutTo = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      sendCalendlyInvite,
    }: {
      referralId: string;
      sendCalendlyInvite: boolean;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/mark-as-okay-to-reach-out-to`,
        {
          sendCalendlyInvite,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchReferralEntries();
      refetchReferralDetails();
    },
  });

  return mutation;
};

export const useAddNewNote = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      note: reachOutAttempt,
    }: {
      referralId: string;
      note: Note;
    }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/referral-note`,
        reachOutAttempt
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralDetails();
      onSuccess();
    },
  });

  return mutation;
};

export const useAddNewReachOutEntry = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      reachOutAttempt,
    }: {
      referralId: string;
      reachOutAttempt: {
        date: Date;
        isSuccess: boolean;
        note: string;
        emailToBeSent: UnsuccessfulyReachOutAttemptEmail;
      };
    }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/reachout-attempt`,
        reachOutAttempt
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
  });

  return mutation;
};

export const useGetReferralDetails = (referralId: string) => {
  const query = useQuery<ReferralDetails>(
    ["referral-manager", "view-details", referralId],
    async () => {
      const res = await axios.get(
        `customerrelationshipmanager/referral/${referralId}`
      );
      return res.data;
    },
    {
      enabled: referralId != undefined,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetActiveEpdEmployees = () => {
  const query = useQuery<EpdEmployee[]>(
    ["referral-manager", "epd-employees-active"],
    async () => {
      const res = await axios.get(`epdemployees/active`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetHealthcareInsurers = (enabled: boolean) => {
  const query = useQuery<HealthcareInsurer[]>(
    ["referral-manager", "healthcare-insurers"],
    async () => {
      const res = await axios.get(
        `customerrelationshipmanagerreport/healthcare-insurers`
      );
      return res.data;
    },
    {
      enabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetEpdLocations = () => {
  const query = useQuery<EpdLocation[]>(
    ["referral-manager", "epd-locations"],
    async () => {
      const res = await axios.get(
        `customerrelationshipmanagerreport/treatment-locations`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetEpdDossierTypes = () => {
  const query = useQuery<EpdDossierType[]>(
    ["referral-manager", "epd-dossier-types"],
    async () => {
      const res = await axios.get(`dossiers/dossier-types`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadProgressWithOccupancyForecast = (
  onSuccess: (data: CaseLoadProgressWithOccupancyForecast[]) => void
) => {
  const query = useQuery<CaseLoadProgressWithOccupancyForecast[]>(
    ["referral-manager", "case-loads", "team-overview", "with-forecast"],
    async () => {
      const res = await axios.get(
        `caseloadmanagerreporting/team-progress-with-occupancy-forecast`
      );
      return res.data;
    },
    {
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: onSuccess,
    }
  );

  return query;
};

export const useGetLocationWithTeamTags = (
  onSuccess: (data: PreIntakeReportTeamAndLocationFilter[]) => void
) => {
  const query = useQuery<PreIntakeReportTeamAndLocationFilter[]>(
    ["referral-manager", "case-loads", "location-with-team-tags"],
    async () => {
      const res = await axios.get(`tags/locations-with-team-tags`);
      return res.data;
    },
    {
      onSuccess: onSuccess,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useUpdatePatientName = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      referralId,
      firstName,
      lastName,
    }: {
      referralId: string;
      firstName: string;
      lastName: string;
    }) => {
      const res = await axios.put(
        `customerrelationshipmanager/referral/${referralId}/patient-name`,
        {
          firstName,
          lastName,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
  });

  return mutation;
};

export const useSendiWelcomeViaCrm = (
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({ referralId }: { referralId: string }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${referralId}/send-iwelcome-email`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      refetchReferralEntries();
      refetchReferralDetails();
      onSuccess();
    },
    onError: onError,
  });

  return mutation;
};
