import {
  useGetOnboardingPatientDetails,
  useUpdatePatientLanguage,
} from "../../hooks";
import { OnboardPatientDetails } from "../../types";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../../../../../components/ui/select";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { DutchFlag, GbFlag } from "../../../../../shared/Flags";

export const Language = ({
  onboardingPatient,
}: {
  onboardingPatient: OnboardPatientDetails;
}) => {
  const { t } = useTranslation();
  const { isFetching } = useGetOnboardingPatientDetails(onboardingPatient.id);

  const { mutate: saveLanguage, isLoading: isSaving } =
    useUpdatePatientLanguage(() => {
      toast(
        t("case-load-manager-onboarding-patient-language-edit-success-title"),
        {
          description: t(
            "case-load-manager-onboarding-patient-language-edit-success-desc"
          ),
        }
      );
    });

  return (
    <div className="flex items-center space-x-2">
      <p className="font-bold">
        {t("case-load-manager-onboarding-details-language")}:
      </p>

      <div className="w-[70px]">
        <Select
          value={onboardingPatient.language}
          onValueChange={async (value) => {
            await saveLanguage({
              id: onboardingPatient.id,
              languageCode: value as "en-US" | "nl-NL",
            });
          }}
          disabled={isSaving || isFetching}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={"nl-NL"} key={"nl-NL"}>
              <DutchFlag />
            </SelectItem>
            <SelectItem value={"en-US"} key={"en-US"}>
              <GbFlag />
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
