import { ColumnDef } from "@tanstack/react-table";
import { AppointmentReminder } from "./types";
import { getSimpleFormattedDateTime } from "../../../caseLoadCockpit/utils";
import { RetriggerReminder } from "./retriggerReminder";

export const columns: ColumnDef<AppointmentReminder>[] = [
  {
    accessorKey: "clientNumber",
    header: "Client",
    size: 120,
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
  {
    accessorKey: "consultStatus",
    header: "Status",
    size: 200,
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
  {
    accessorKey: "consultStart",
    header: "Start of conultation",
    size: 200,
    cell: ({ getValue }) => {
      return <>{getSimpleFormattedDateTime(getValue() as Date)}</>;
    },
  },
  {
    accessorKey: "wireUserHandle",
    header: "Wire handle",
    size: 300,
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
  {
    accessorKey: "sentOn",
    header: "Sent On",
    size: 200,
    cell: ({ getValue }) => {
      const value = getValue() as Date | null;
      return <>{value ? getSimpleFormattedDateTime(value) : ""}</>;
    },
  },
  {
    accessorKey: "retrigger",
    header: "",
    size: 150,
    cell: ({ row }) => {
      return (
        <div className="flex justify-end">
          <RetriggerReminder id={row.original.epdAppointmentId} />
        </div>
      );
    },
  },
];
