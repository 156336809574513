import { Loader2 } from "lucide-react";

import { UserToAddToChannel } from "../../../../../types";
import { CommandItem } from "../../../../../../../components/ui/command";
import { useAddUserToChat } from "../../../../hooks";
import { toast } from "sonner";

export const SelectableUserToAddToChannel = ({
  channelId,
  user,
  onClose,
}: {
  channelId: string;
  user: UserToAddToChannel;
  onClose: () => void;
}) => {
  const { mutate: addUserToChat, isLoading: isLoadingAddUserToChat } =
    useAddUserToChat(() => {
      toast("User added to chat", {
        description: "The user has been added to the chat",
      });
      onClose();
    });

  return (
    <CommandItem
      className="cursor-pointer text-muted-foreground hover:bg-muted flex items-center space-x-2"
      key={user.email}
      onSelect={async (currentValue) => {
        await addUserToChat({
          channelId,
          email: user.email,
          userName: user.userName,
        });
      }}
    >
      <p>
        {user.userName} ({user.email})
      </p>
      {isLoadingAddUserToChat ? (
        <div className="h-4 w-4">
          <Loader2 className="w-4 h-4" />
        </div>
      ) : (
        <></>
      )}
    </CommandItem>
  );
};
