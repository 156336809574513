import { Header } from "./header/header";
import { DefaultGenerics, StreamChat } from "stream-chat";
import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
  TabContext,
} from "../../../../components/ui/horizontalTab";
import { CaseloadChat } from "./chat-and-video/chat/chat";
import { useContext, useState } from "react";
import { StreamMessage } from "stream-chat-react";
import { StreamChatGenerics } from "./chat-and-video/types";
import { ResourceSuggestion } from "./aiRecommendations/types";
import { AiRecommendations } from "./aiRecommendations/aiRecommendations";
import { useGetAssignedPatient } from "../hooks";
import { Skeleton } from "../../../../components/ui/skeleton";
import { useIsEnabled } from "../../../../feature-management/useIsEnabled";
import { KopModel } from "./kopModel/kopModel";
import { TreatmentDetails } from "./treatmentDetails/treatmentDetails";

export const CaseLoadClientDetails = ({
  clientId,
  dossierLookupId,
  chatClient,
  couldNotLoadChat,
  onClose,
}: {
  clientId: string;
  dossierLookupId: string;
  chatClient: StreamChat<DefaultGenerics> | null;
  couldNotLoadChat: boolean;
  onClose: () => void;
}) => {
  const { isLoading } = useGetAssignedPatient(clientId);

  if (isLoading) {
    return (
      <div className="min-h-[90vh] min-w-full">
        <Skeleton className="h-14 w-full rounded" />
        <div className="mt-6 flex items-center justify-between space-x-6">
          <Skeleton className="h-44 w-1/2 rounded" />
          <Skeleton className="h-44 w-1/2 rounded" />
        </div>
        <Skeleton className="mt-6 h-72 w-full rounded" />
      </div>
    );
  }

  return (
    <main className="-mt-6 min-w-full max-h-[95vh]">
      <Header clientId={clientId} onClose={onClose} />

      <section className="pt-6">
        <HorizontalTab>
          <Tabs
            clientId={clientId}
            dossierLookupId={dossierLookupId}
            chatClient={chatClient}
            couldNotLoadChat={couldNotLoadChat}
          />
        </HorizontalTab>
      </section>
    </main>
  );
};

const Tabs = ({
  clientId,
  dossierLookupId,
  chatClient,
  couldNotLoadChat,
}: {
  clientId: string;
  dossierLookupId: string;
  chatClient: StreamChat<DefaultGenerics> | null;
  couldNotLoadChat: boolean;
}) => {
  const { setActiveTab } = useContext(TabContext)!;

  const { data: isCaseLoadManagerAiChatAssistantEnabled } = useIsEnabled(
    "EnableCaseLoadManagerAiChatAssistant"
  );

  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );

  const [defaultChatMessage, setDefaultChatMessage] = useState<
    StreamMessage<StreamChatGenerics> | undefined
  >(undefined);

  const onReplySuggestionSelected = (suggestion: string) => {
    setActiveTab(0);
    setDefaultChatMessage({
      id: "???",
      type: "regular",
      text: suggestion,
    });
  };
  const onResourceSuggestionSelected = (resource: ResourceSuggestion) => {
    setActiveTab(0);
    setDefaultChatMessage({
      id: "???",
      type: "regular",
      text: resource.description,
      attachments: [
        {
          // todo: when AI API is ready, use the AI provided attachment
          type: "application/pdf",
          asset_url: "https://pdfobject.com/pdf/sample.pdf",
          title: resource.title,
        },
      ],
    });
  };

  type TabDetails = {
    title: string;
    component: JSX.Element;
  };

  const chatTab: TabDetails = {
    title: "Chat",
    component: (
      <CaseloadChat
        dossierLookupId={dossierLookupId}
        chatClient={chatClient}
        couldNotLoadChat={couldNotLoadChat}
        defaultMessage={defaultChatMessage}
      />
    ),
  };

  const chatAssistantTab: TabDetails = {
    title: "Chat assistant",
    component: (
      <AiRecommendations
        onReplySuggestionSelected={onReplySuggestionSelected}
        onResourceSuggestionSelected={onResourceSuggestionSelected}
      />
    ),
  };

  const kopModelTab: TabDetails = {
    title: "KOP-model",
    component: <KopModel clientId={clientId} />,
  };

  const treatmentTab: TabDetails = {
    title: "Treatment",
    component: <TreatmentDetails clientId={clientId} />,
  };

  const getTabsToDisplay = () => {
    let tabsToDisplay = [kopModelTab, treatmentTab];

    if (isCaseLoadManagerAiChatAssistantEnabled) {
      tabsToDisplay.splice(0, 0, chatAssistantTab);
    }

    if (isCaseLoadManagerWireReplacementEnabled && chatClient) {
      tabsToDisplay.splice(0, 0, chatTab);
    }

    return tabsToDisplay;
  };

  return (
    <div className="w-full mb-12">
      <div className="flex items-center ml-3">
        {getTabsToDisplay().map((t, index) => {
          return (
            <HorizontalTabHeader key={index} index={index}>
              {t.title}
            </HorizontalTabHeader>
          );
        })}
      </div>

      {getTabsToDisplay().map((t, index) => {
        return (
          <HorizontalTabContent key={index} index={index}>
            {t.component}
          </HorizontalTabContent>
        );
      })}
    </div>
  );
};
