import { WeeklyInflowCard } from "./cards/weeklyInflow";
import { OpenClientsCard } from "./cards/openClients";
import { getCurrentMondayAndFriday } from "../../../../caseLoadCockpit/utils";
import { useState } from "react";
import {
  useGetWeeklyInflowStatisticsReport,
  useGetMonthlyInflowAggregationReport,
} from "../../hooks";
import { StatisticsFilter } from "./filters/statisticsfilter";
import { getWeek } from "date-fns";
import { ReferralsPerInsurersTable } from "./tables/referralsPerInsurer";
import CurrentWeekReferralsChart from "./charts/currentWeekReferralsChart";
import TrendsChart from "./charts/trendsChart";
import { ReferralsPerInsurersPerMonthTable } from "./tables/referralsPerInsurersPerMonth";

export const InflowReport = () => {
  const { monday } = getCurrentMondayAndFriday();
  const [viewType, setViewType] = useState<"week" | "month">("week");
  const [selectedDate, setSelectedDate] = useState<Date>(monday);
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [healthcareInsurerIds, setHealthcareInsurerIds] = useState<string[]>(
    []
  );
  const [registrationStatuses, setRegistrationStatuses] = useState<string[]>(
    []
  );
  const [contractTypes, setContractTypes] = useState<string[]>([]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const { data: inflowReport, isLoading: isLoadingInflowReport } =
    useGetWeeklyInflowStatisticsReport(
      selectedDate,
      locationIds,
      registrationStatuses,
      healthcareInsurerIds,
      contractTypes
    );

  const {
    data: inflowMonthlyAggregationReport,
    isLoading: isLoadingInflowMonthlyAggregationReport,
  } = useGetMonthlyInflowAggregationReport();

  return (
    <main>
      <section className="w-full flex items-center justify-between">
        <div className="w-1/2">
          <h1 className="text-2xl font-bold">
            Inflow report{" "}
            <span className="font-medium">- CRM / Patient Referral</span>
          </h1>
          <p>The number referrals received (ZorgDomein & manually created)</p>
        </div>

        <div className="w-1/2">
          <StatisticsFilter
            monday={monday}
            viewType={viewType}
            setViewType={setViewType}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            registrationStatuses={registrationStatuses}
            setRegistrationStatuses={setRegistrationStatuses}
            locationIds={locationIds}
            setLocationIds={setLocationIds}
            healthcareInsurerIds={healthcareInsurerIds}
            setHealthcareInsurerIds={setHealthcareInsurerIds}
            contractTypes={contractTypes}
            setContractTypes={setContractTypes}
          />
        </div>
      </section>

      <section className="my-4">
        <div className="rounded bg-blue-50 p-2">
          <p>
            <span className="font-bold">
              {viewType == "month" ? "Month View: " : "Week View: "}
            </span>
            <span>
              {viewType == "month"
                ? monthNames[selectedDate.getMonth()]
                : `${getWeek(
                    selectedDate
                  )} (From: ${selectedDate.toLocaleDateString()})`}
            </span>
          </p>
        </div>
      </section>

      <section className="mt-6 flex items-center space-x-2">
        <WeeklyInflowCard
          data={inflowReport}
          isLoading={isLoadingInflowReport}
        />
        <OpenClientsCard
          data={inflowReport}
          isLoading={isLoadingInflowReport}
        />
      </section>

      <section className="mt-12">
        <ReferralsPerInsurersTable
          data={inflowReport}
          isLoading={isLoadingInflowReport}
        />
        <div className="mt-8">
          <ReferralsPerInsurersPerMonthTable
            data={inflowMonthlyAggregationReport ?? []}
            isLoading={isLoadingInflowMonthlyAggregationReport}
          />
        </div>

        <div className="mt-12 flex items-start space-x-12 justify-between w-full">
          <div className="w-1/2 border rounded p-6">
            <h1 className="text-xl text-center font-bold pb-4">
              Statuses on selected week
            </h1>
            <CurrentWeekReferralsChart weeklyStatistics={inflowReport} />
          </div>
          <div className="w-1/2 border rounded p-6">
            <h1 className="text-xl text-center font-bold pb-4">
              Historic inflow (last 2 months)
            </h1>
            <TrendsChart />
          </div>
        </div>
      </section>
    </main>
  );
};
