export type OnboardPatientMainListEntry = {
  id: string;
  patientId: string;
  dossierLookupGuid: string;
  dossierId: number;
  epdPatientId: number;
  epdFileId: number;
  name: string;
  preIntakeOn: Date;
  intakeOn: Date | null;
  intakePsychologistName: string | null;
  kopModelStatus: KopModelStatus;
  welcomeMessageSent: boolean;
  treatmentStartStandardMessageSent: boolean;
};

export type OnboardPatientDetails = Omit<
  OnboardPatientMainListEntry,
  "kopModelStatus"
> & {
  personalizedWelcomeMessage: string | null;
  language: "en-US" | "nl-NL";
  kopModel: KopModelFields & {
    status: KopModelStatus;
  };
};

export type KopModelFields = {
  language: KopModelLanguage;
  complaints: string | null;
  circumstances: string | null;
  personalStyle: string | null;
  requestForHelp: string | null;
  questionsForPreIntake: string | null;
  myCommentsForDiagnosisAndTreatment: string | null;
};

export enum KopModelStatus {
  NotStarted = "NotYetStarted",
  Draft = "Draft",
  Sent = "Sent",
}

export enum KopModelLanguage {
  Dutch = "Dutch",
  English = "English",
}

export type PraktijkdataTreatmentInfo = {
  anamnesis: string;
  psychologicalHistory: string;
  medicalComorbidity: string;
  medication: string;
  substanceUsage: string;
  socialEnvironment: string;
  personality: string;
  careRequest: string;
  treatmentGoals: string;
};
