import { useState } from "react";
import { Button } from "../../../../../components/ui/button";
import { Loader2, SquarePen } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { WysiwygEditor } from "../../../../../components/ui/wysiwyg-editor";
import {
  useGetPersonalizedKopTemplates,
  useUpdatePersonalizedKopTemplate,
} from "../../hooks";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { Textarea } from "../../../../../components/ui/textarea";
import { useIsEnabled } from "../../../../../feature-management/useIsEnabled";
import { KopModelLanguage } from "../../types";
import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
} from "../../../../../components/ui/horizontalTab";

export const MyKopModelTemplateButton = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { data: isWysiwygEnabled } = useIsEnabled("EnableWysiwygKopModel");

  const [nlPreIntakeQuestions, setNlPreIntakeQuestions] = useState("");
  const [enPreIntakeQuestions, setEnPreIntakeQuestions] = useState("");
  const [nlComplaints, setNlComplains] = useState("");
  const [enComplaints, setEnComplains] = useState("");
  const [nlCircumstances, setNlCircumstances] = useState("");
  const [enCircumstances, setEnCircumstances] = useState("");
  const [nlPersonalStyle, setNlPersonalStyle] = useState("");
  const [enPersonalStyle, setEnPersonalStyle] = useState("");
  const [
    nlMyCommentsForDiagnosisAndTreatment,
    setNlMyCommentsForDiagnosisAndTreatment,
  ] = useState("");
  const [
    enMyCommentsForDiagnosisAndTreatment,
    setEnMyCommentsForDiagnosisAndTreatment,
  ] = useState("");
  const [nlRequestForHelp, setNlRequestForHelp] = useState("");
  const [enRequestForHelp, setEnRequestForHelp] = useState("");

  useGetPersonalizedKopTemplates((data) => {
    const nlTemplate = data.find((x) => x.language == KopModelLanguage.Dutch);

    if (nlTemplate) {
      setNlPreIntakeQuestions(nlTemplate?.questionsForPreIntake ?? "");
      setNlComplains(nlTemplate?.complaints ?? "");
      setNlCircumstances(nlTemplate?.circumstances ?? "");
      setNlPersonalStyle(nlTemplate?.personalStyle ?? "");
      setNlRequestForHelp(nlTemplate?.requestForHelp ?? "");
      setNlMyCommentsForDiagnosisAndTreatment(
        nlTemplate?.myCommentsForDiagnosisAndTreatment ?? ""
      );
    }

    const enTemplate = data.find((x) => x.language == KopModelLanguage.English);
    if (enTemplate) {
      setEnPreIntakeQuestions(enTemplate?.questionsForPreIntake ?? "");
      setEnComplains(enTemplate?.complaints ?? "");
      setEnCircumstances(enTemplate?.circumstances ?? "");
      setEnPersonalStyle(enTemplate?.personalStyle ?? "");
      setEnRequestForHelp(enTemplate?.requestForHelp ?? "");
      setEnMyCommentsForDiagnosisAndTreatment(
        enTemplate?.myCommentsForDiagnosisAndTreatment ?? ""
      );
    }
  });

  const { mutate: save, isLoading: isSaving } =
    useUpdatePersonalizedKopTemplate(() => {
      toast("Template update", {
        description: "Your personal KOP model template has been updated.",
      });

      setIsOpen(false);
    });

  return (
    <>
      <Button
        variant="outline"
        className="flex items-center space-x-2"
        onClick={() => setIsOpen(true)}
      >
        <div className="w-4 h-4">
          <SquarePen className="w-4 h-4" />
        </div>
        <p>{t("case-load-manager-onboarding-kop-model-template-button")}</p>
      </Button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="min-w-[75vw] max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>
              KOP Model Personal Template{isWysiwygEnabled ? "s" : ""}
            </DialogTitle>
          </DialogHeader>

          {isWysiwygEnabled ? (
            <HorizontalTab>
              <div className="w-full mb-12">
                <div className="flex items-center ml-3">
                  <HorizontalTabHeader index={0}>
                    Nederlands
                  </HorizontalTabHeader>
                  <HorizontalTabHeader index={1}>English</HorizontalTabHeader>
                </div>
                <HorizontalTabContent index={0}>
                  <TemplateByLanguage
                    preIntakeQuestions={nlPreIntakeQuestions}
                    complaints={nlComplaints}
                    circumstances={nlCircumstances}
                    personalStyle={nlPersonalStyle}
                    requestForHelp={nlRequestForHelp}
                    myCommentsForDiagnosisAndTreatment={
                      nlMyCommentsForDiagnosisAndTreatment
                    }
                    setPreIntakeQuestions={setNlPreIntakeQuestions}
                    setComplains={setNlComplains}
                    setCircumstances={setNlCircumstances}
                    setPersonalStyle={setNlPersonalStyle}
                    setRequestForHelp={setNlRequestForHelp}
                    setMyCommentsForDiagnosisAndTreatment={
                      setNlMyCommentsForDiagnosisAndTreatment
                    }
                  />
                </HorizontalTabContent>
                <HorizontalTabContent index={1}>
                  <TemplateByLanguage
                    preIntakeQuestions={enPreIntakeQuestions}
                    complaints={enComplaints}
                    circumstances={enCircumstances}
                    personalStyle={enPersonalStyle}
                    requestForHelp={enRequestForHelp}
                    myCommentsForDiagnosisAndTreatment={
                      enMyCommentsForDiagnosisAndTreatment
                    }
                    setPreIntakeQuestions={setEnPreIntakeQuestions}
                    setComplains={setEnComplains}
                    setCircumstances={setEnCircumstances}
                    setPersonalStyle={setEnPersonalStyle}
                    setRequestForHelp={setEnRequestForHelp}
                    setMyCommentsForDiagnosisAndTreatment={
                      setEnMyCommentsForDiagnosisAndTreatment
                    }
                  />
                </HorizontalTabContent>
              </div>
            </HorizontalTab>
          ) : (
            <section>
              <div className="mt-6">
                <p className="font-bold">
                  {t(
                    "case-load-manager-onboarding-kop-model-caption-preintake-questions"
                  )}
                </p>

                {isWysiwygEnabled ? (
                  <WysiwygEditor
                    value={nlPreIntakeQuestions}
                    setValue={setNlPreIntakeQuestions}
                    minHeight="100px"
                  />
                ) : (
                  <Textarea
                    className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                    value={nlPreIntakeQuestions}
                    onChange={(e) => setNlPreIntakeQuestions(e.target.value)}
                    rows={3}
                  />
                )}
              </div>

              <div className="mt-4 flex space-x-4 items-start">
                <div className="w-1/3">
                  <p className="font-bold">
                    {t("case-load-manager-onboarding-kop-model-caption-k")}
                  </p>
                  {isWysiwygEnabled ? (
                    <WysiwygEditor
                      value={nlComplaints}
                      setValue={setNlComplains}
                      minHeight="250px"
                    />
                  ) : (
                    <Textarea
                      className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                      value={nlComplaints}
                      onChange={(e) => setNlComplains(e.target.value)}
                      rows={5}
                    />
                  )}
                </div>
                <div className="w-1/3">
                  <p className="font-bold">
                    {t("case-load-manager-onboarding-kop-model-caption-o")}
                  </p>
                  {isWysiwygEnabled ? (
                    <WysiwygEditor
                      value={nlCircumstances}
                      setValue={setNlCircumstances}
                      minHeight="250px"
                    />
                  ) : (
                    <Textarea
                      className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                      value={nlCircumstances}
                      onChange={(e) => setNlCircumstances(e.target.value)}
                      rows={5}
                    />
                  )}
                </div>

                <div className="w-1/3">
                  <p className="font-bold">
                    {t("case-load-manager-onboarding-kop-model-caption-p")}
                  </p>
                  {isWysiwygEnabled ? (
                    <WysiwygEditor
                      value={nlPersonalStyle}
                      setValue={setNlPersonalStyle}
                      minHeight="250px"
                    />
                  ) : (
                    <Textarea
                      className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                      value={nlPersonalStyle}
                      onChange={(e) => setNlPersonalStyle(e.target.value)}
                      rows={5}
                    />
                  )}
                </div>
              </div>

              <div className="mt-4">
                <p className="font-bold">
                  {t(
                    "case-load-manager-onboarding-kop-model-caption-request-for-help"
                  )}
                </p>

                {isWysiwygEnabled ? (
                  <WysiwygEditor
                    value={nlRequestForHelp}
                    setValue={setNlRequestForHelp}
                    minHeight="100px"
                  />
                ) : (
                  <Textarea
                    className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                    value={nlRequestForHelp}
                    onChange={(e) => setNlRequestForHelp(e.target.value)}
                    rows={3}
                  />
                )}
              </div>

              <div className="mt-4">
                <p className="font-bold">
                  {t(
                    "case-load-manager-onboarding-kop-model-caption-questions"
                  )}
                </p>
                {isWysiwygEnabled ? (
                  <WysiwygEditor
                    value={nlMyCommentsForDiagnosisAndTreatment}
                    setValue={setNlMyCommentsForDiagnosisAndTreatment}
                    minHeight="100px"
                  />
                ) : (
                  <Textarea
                    className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                    value={nlMyCommentsForDiagnosisAndTreatment}
                    onChange={(e) =>
                      setNlMyCommentsForDiagnosisAndTreatment(e.target.value)
                    }
                    rows={3}
                  />
                )}
              </div>
            </section>
          )}

          <DialogFooter className="gap-2 flex items-center justify-end">
            <Button
              variant="outline"
              onClick={() => setIsOpen(false)}
              className="px-4"
            >
              {t("case-load-manager-onboarding-kop-model-template-close")}
            </Button>
            <Button
              onClick={async (e) => {
                e.preventDefault();
                await save({
                  nlTemplate: {
                    complaints: nlComplaints,
                    circumstances: nlCircumstances,
                    personalStyle: nlPersonalStyle,
                    requestForHelp: nlRequestForHelp,
                    questionsForPreIntake: nlPreIntakeQuestions,
                    myCommentsForDiagnosisAndTreatment:
                      nlMyCommentsForDiagnosisAndTreatment,
                  },
                  enTemplate: {
                    complaints: enComplaints,
                    circumstances: enCircumstances,
                    personalStyle: enPersonalStyle,
                    requestForHelp: enRequestForHelp,
                    questionsForPreIntake: enPreIntakeQuestions,
                    myCommentsForDiagnosisAndTreatment:
                      enMyCommentsForDiagnosisAndTreatment,
                  },
                });
              }}
              className="px-4 flex items-center space-x-2"
            >
              {isSaving && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>{t("case-load-manager-onboarding-kop-model-template-save")}</p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

const TemplateByLanguage = ({
  preIntakeQuestions,
  complaints,
  circumstances,
  personalStyle,
  requestForHelp,
  myCommentsForDiagnosisAndTreatment,
  setPreIntakeQuestions,
  setComplains,
  setCircumstances,
  setPersonalStyle,
  setRequestForHelp,
  setMyCommentsForDiagnosisAndTreatment,
}: {
  preIntakeQuestions: string;
  complaints: string;
  circumstances: string;
  personalStyle: string;
  requestForHelp: string;
  myCommentsForDiagnosisAndTreatment: string;
  setPreIntakeQuestions: React.Dispatch<React.SetStateAction<string>>;
  setComplains: React.Dispatch<React.SetStateAction<string>>;
  setCircumstances: React.Dispatch<React.SetStateAction<string>>;
  setPersonalStyle: React.Dispatch<React.SetStateAction<string>>;
  setRequestForHelp: React.Dispatch<React.SetStateAction<string>>;
  setMyCommentsForDiagnosisAndTreatment: React.Dispatch<
    React.SetStateAction<string>
  >;
}) => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="mt-6">
        <p className="font-bold">
          {t(
            "case-load-manager-onboarding-kop-model-caption-preintake-questions"
          )}
        </p>

        <WysiwygEditor
          value={preIntakeQuestions}
          setValue={setPreIntakeQuestions}
          minHeight="100px"
        />
      </div>

      <div className="mt-4 flex space-x-4 items-start">
        <div className="w-1/3">
          <p className="font-bold">
            {t("case-load-manager-onboarding-kop-model-caption-k")}
          </p>
          <WysiwygEditor
            value={complaints}
            setValue={setComplains}
            minHeight="250px"
          />
        </div>
        <div className="w-1/3">
          <p className="font-bold">
            {t("case-load-manager-onboarding-kop-model-caption-o")}
          </p>
          <WysiwygEditor
            value={circumstances}
            setValue={setCircumstances}
            minHeight="250px"
          />
        </div>

        <div className="w-1/3">
          <p className="font-bold">
            {t("case-load-manager-onboarding-kop-model-caption-p")}
          </p>
          <WysiwygEditor
            value={personalStyle}
            setValue={setPersonalStyle}
            minHeight="250px"
          />
        </div>
      </div>

      <div className="mt-4">
        <p className="font-bold">
          {t("case-load-manager-onboarding-kop-model-caption-request-for-help")}
        </p>

        <WysiwygEditor
          value={requestForHelp}
          setValue={setRequestForHelp}
          minHeight="100px"
        />
      </div>

      <div className="mt-4">
        <p className="font-bold">
          {t("case-load-manager-onboarding-kop-model-caption-questions")}
        </p>
        <WysiwygEditor
          value={myCommentsForDiagnosisAndTreatment}
          setValue={setMyCommentsForDiagnosisAndTreatment}
          minHeight="100px"
        />
      </div>
    </section>
  );
};
