import { SearchTermInput } from "../../filters/searchTermInput";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../../components/ui/alert";
import {
  ViewType,
  ViewTypes,
  ReferredPatient,
  HealthcareInsurerContractFilter,
  getReadableViewType,
  TransferReferralFilter,
} from "../../types";
import { Table } from "@tanstack/react-table";
import { Customize } from "../customization/customize";
import { AddManualReferral } from "../manualReferral";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectGroup,
  SelectValue,
} from "../../../../components/ui/select";
import { RocketIcon } from "lucide-react";

export const TableHeader = ({
  isLoading,
  table,
  openDetailsSheet,
  pageSize,
  setPageSize,
  addNewPatientOpen: addManualReferralOpen,
  setAddNewPatientOpen: setAddManualReferralOpen,
  searchTerm,
  setSearchTerm,
  registrationStatuses,
  setRegistrationStatuses,
  nextSteps,
  setNextSteps,
  locationIds,
  setLocationIds,
  healthcareInsurerIds,
  setHealthcareInsurerIds,
  viewType,
  setViewType,
  transferFilter,
  setTransferFilter,
  contractFilter,
  setContractFilter,
}: {
  isLoading: boolean;
  table: Table<ReferredPatient>;
  openDetailsSheet: (id: string) => void;
  pageSize: number;
  setPageSize: (newSize: number) => void;
  addNewPatientOpen: boolean;
  setAddNewPatientOpen: (newOpen: boolean) => void;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  registrationStatuses: string[];
  setRegistrationStatuses: (newStatuses: string[]) => void;
  nextSteps: string[];
  setNextSteps: (newStatuses: string[]) => void;
  locationIds: string[];
  setLocationIds: (newIds: string[]) => void;
  healthcareInsurerIds: string[];
  setHealthcareInsurerIds: (newIds: string[]) => void;
  viewType: ViewType;
  setViewType: (newValue: ViewType) => void;
  transferFilter: TransferReferralFilter;
  setTransferFilter: (newValue: TransferReferralFilter) => void;
  contractFilter: string;
  setContractFilter: (newValue: HealthcareInsurerContractFilter) => void;
}) => {
  const areFiltersActive = () => {
    return (
      searchTerm.trim() !== "" &&
      (registrationStatuses.length > 0 ||
        nextSteps.length > 0 ||
        locationIds.length > 0 ||
        transferFilter !== TransferReferralFilter.All ||
        healthcareInsurerIds.length > 0 ||
        contractFilter.trim() !== "All" ||
        viewType !== ViewTypes.fullView)
    );
  };

  return (
    <div className="flex items-center justify-between">
      <div className="w-1/3">
        <SearchTermInput
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        {areFiltersActive() && (
          <Alert className="border-gray-400 text-gray-500 text-s mt-2">
            <RocketIcon className="h-4 w-4" />
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription className="text-s">
              You are using a filtered view. To see all patients, switch to Full
              View and Reset Filters.
            </AlertDescription>
          </Alert>
        )}
      </div>
      <div className="w-1/2 flex justify-end space-x-2">
        <Select
          onValueChange={(value) => {
            localStorage.setItem("crm-main-list-view-type", value);
            setViewType(value as ViewType);
          }}
          value={viewType}
          disabled={isLoading}
        >
          <SelectTrigger className="w-[225px] font-bold">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {[
                ViewTypes.dagcoView,
                ViewTypes.workingViewWithoutWaitingList,
                ViewTypes.workingViewWithWaitingList,
                ViewTypes.calendlyView,
                ViewTypes.fullView,
              ].map((vt) => (
                <SelectItem key={vt} value={vt}>
                  {getReadableViewType(vt)}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        <Customize
          table={table}
          viewType={viewType}
          pageSize={pageSize}
          setPageSize={setPageSize}
          registrationStatuses={registrationStatuses}
          setRegistrationStatuses={setRegistrationStatuses}
          nextSteps={nextSteps}
          setNextSteps={setNextSteps}
          locationIds={locationIds}
          setLocationIds={setLocationIds}
          healthcareInsurerIds={healthcareInsurerIds}
          setHealthcareInsurerIds={setHealthcareInsurerIds}
          transferFilter={transferFilter}
          setTransferFilter={setTransferFilter}
          contractFilter={contractFilter}
          setContractFilter={setContractFilter}
        />
        <AddManualReferral
          open={addManualReferralOpen}
          onOpenChange={setAddManualReferralOpen}
          isLoading={isLoading}
          openDetailsSheet={openDetailsSheet}
        />
      </div>
    </div>
  );
};
