import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../../types";
import { useTranslation } from "react-i18next";
import { YourComments } from "../../psychologistComments/yourComments";

export const YourCommentsColName = "Your Comments";

export const YourCommentsCol: ColumnDef<MainListPatient> = {
  id: YourCommentsColName,
  accessorKey: "psychologistComment",
  size: 225,
  minSize: 100,
  header: () => {
    const { t } = useTranslation();
    return <p>{t("case-load-manager-column-your-comments")}</p>;
  },
  cell: ({ row }) => {
    return (
      <div className="py-2 px-4">
        <YourComments data={row.original} />
      </div>
    );
  },
};
