import { TreatmentComment } from "../../treatment/treatmentComment";
import { useGetAssignedPatient } from "../../hooks";
import { TreatmentAiSummary } from "../../treatment/aiSummary";
import { useTranslation } from "react-i18next";

export const TreatmentDetails = ({ clientId }: { clientId: string }) => {
  const { data } = useGetAssignedPatient(clientId);
  const { t } = useTranslation();

  if (!data) return <></>;

  return (
    <main className="w-full flex flex-col">
      <div className="flex items-start space-x-6">
        <div className="w-2/3">
          <TreatmentAiSummary data={data} />
        </div>

        <div className="w-1/3">
          <div className="text-lg font-bold">Treatment Comments</div>
          <div className="mt-4">
            <TreatmentComment data={data} />
          </div>
        </div>
      </div>

      <div className="pt-6 mt-6 border-t w-full whitespace-pre-wrap">
        <h1 className="text-lg font-bold">Full Praktijkdata Plan</h1>
        <p>
          {data.epdTreatment.treatmentPlan
            ? data.epdTreatment.treatmentPlan
            : "No treatment plan found."}
        </p>
      </div>
    </main>
  );
};
