import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
} from "../../../../components/ui/horizontalTab";
import { KopModelAndWelcomeMessageStep } from "./onboardingSteps/kopModelAndWelcomeMessage";
import { TreatmentStartMessageStep } from "./onboardingSteps/treatmentStartMessage";
import { IntakeDates } from "./onboardingSteps/intakeDates";
import { WireUserName } from "./onboardingSteps/wireUserName";
import { Language } from "./onboardingSteps/language";
import { DossierLinks } from "./onboardingSteps/dossierLinks";
import { useGetOnboardingPatientDetails } from "../hooks";
import { KopModelInput } from "./kopModelInput/kopModelInput";
import { Skeleton } from "../../../../components/ui/skeleton";
import { KopModelStatus } from "../types";
import { getDidIntakeHappen } from "../utils";
import { CaseloadChat } from "../../caseLoadOverview/clientDetails/chat-and-video/chat/chat";
import { DefaultGenerics, StreamChat } from "stream-chat";
import { useIsEnabled } from "../../../../feature-management/useIsEnabled";

export const OnboardingPatientDetail = ({
  id,
  chatClient,
}: {
  id: string;
  chatClient: StreamChat<DefaultGenerics> | null;
}) => {
  const { data: onboardingPatient, isLoading } =
    useGetOnboardingPatientDetails(id);

  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );

  if (isLoading) {
    return (
      <div className="min-h-[90vh] min-w-full">
        <Skeleton className="h-14 w-full rounded" />
        <div className="mt-6 flex items-center justify-between space-x-6">
          <Skeleton className="h-44 w-1/2 rounded" />
          <Skeleton className="h-44 w-1/2 rounded" />
        </div>
        <Skeleton className="mt-6 h-72 w-full rounded" />
      </div>
    );
  }

  if (!onboardingPatient) {
    return (
      <div className="min-h-[90vh] min-w-full flex flex-col space-y-1 items-center justify-center bg-red-100 text-red-500">
        <h1 className="text-2xl font-bold">Sorry, something went wrong.</h1>
        <h2 className="text-lg">
          Please try again later, or contact the support team.
        </h2>
      </div>
    );
  }

  const shouldHideKopModel =
    getDidIntakeHappen(onboardingPatient) &&
    onboardingPatient.kopModel.status !== KopModelStatus.Sent;

  return (
    <div className="min-h-[90vh] min-w-full">
      <div className="text-3xl border-b pb-2 mb-2">
        {onboardingPatient.epdPatientId}.{onboardingPatient.epdFileId}{" "}
        <span className="font-bold">{onboardingPatient.name}</span>
      </div>

      <section className="my-4 p-2">
        <div className="flex justify-between space-x-12 items-start">
          <section className="w-1/2 space-y-2">
            <p className="text-lg mb-2">Onboarding</p>
            <KopModelAndWelcomeMessageStep
              onboardingPatient={onboardingPatient}
            />
            <TreatmentStartMessageStep onboardingPatient={onboardingPatient} />
          </section>
          <div className="w-1/2 min-h-full space-y-1 pt-8">
            <IntakeDates onboardingPatient={onboardingPatient} />

            <div className="pt-2 border-t space-y-2">
              <WireUserName onboardingPatient={onboardingPatient} />
              <Language onboardingPatient={onboardingPatient} />
            </div>

            <div className="pt-2 border-t">
              <DossierLinks onboardingPatient={onboardingPatient} />
            </div>
          </div>
        </div>
      </section>

      {shouldHideKopModel ? (
        <></>
      ) : (
        <HorizontalTab>
          <div className="w-full mt-4 mb-12">
            <div className="flex items-center ml-3">
              <HorizontalTabHeader index={0}>KOP-model</HorizontalTabHeader>
              {isCaseLoadManagerWireReplacementEnabled ? (
                <HorizontalTabHeader index={1}>Chat</HorizontalTabHeader>
              ) : (
                <></>
              )}
            </div>
            <HorizontalTabContent index={0}>
              <KopModelInput onboardingPatientId={onboardingPatient.id} />
            </HorizontalTabContent>
            {isCaseLoadManagerWireReplacementEnabled ? (
              <HorizontalTabContent index={1}>
                <CaseloadChat
                  dossierLookupId={onboardingPatient.dossierLookupGuid}
                  chatClient={chatClient}
                  defaultMessage={undefined}
                />
              </HorizontalTabContent>
            ) : (
              <></>
            )}
          </div>
        </HorizontalTab>
      )}
    </div>
  );
};
