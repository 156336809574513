import { useState } from "react";
import { Checkbox } from "../../../../components/ui/checkbox";
import { useGetSteps, useUpdateSteps } from "./hooks";

export const CrmPatientEntryPatientRegistrationStepTracker = ({
  referralId,
  step,
}: {
  referralId: string;
  step:
    | "name"
    | "schedule-intake"
    | "schedule-pre-intake"
    | "register-patient"
    | "approve-patient"
    | "send-i-welcome"
    | "praktijkdata-actions";
}) => {
  const [value, setValue] = useState(false);

  const {
    data: defaultValues,
    isLoading,
    isFetching,
  } = useGetSteps(referralId, (initialData) => {
    if (step == "name") {
      setValue(initialData.isNameStepCompleted);
    } else if (step == "schedule-intake") {
      setValue(initialData.isIntakeSchedulingStepCompleted);
    } else if (step == "schedule-pre-intake") {
      setValue(initialData.isPreIntakeSchedulingStepCompleted);
    } else if (step == "register-patient") {
      setValue(initialData.isRegisterPatientStepCompleted);
    } else if (step == "approve-patient") {
      setValue(initialData.isApprovePatientStepCompleted);
    } else if (step == "send-i-welcome") {
      setValue(initialData.isSendIWelcomeStepCompleted);
    } else if (step == "praktijkdata-actions") {
      setValue(initialData.isPraktijkdataActionsStepCompleted);
    }
  });

  const { mutate: save, isLoading: isSaving } = useUpdateSteps();

  const onChangeValue = () => {
    const newValue = !value;
    setValue(newValue);

    save({
      data: {
        referralId,
        isNameStepCompleted:
          step == "name" ? newValue : defaultValues!.isNameStepCompleted,
        isIntakeSchedulingStepCompleted:
          step == "schedule-intake"
            ? newValue
            : defaultValues!.isIntakeSchedulingStepCompleted,
        isPreIntakeSchedulingStepCompleted:
          step == "schedule-pre-intake"
            ? newValue
            : defaultValues!.isPreIntakeSchedulingStepCompleted,
        isRegisterPatientStepCompleted:
          step == "register-patient"
            ? newValue
            : defaultValues!.isRegisterPatientStepCompleted,
        isApprovePatientStepCompleted:
          step == "approve-patient"
            ? newValue
            : defaultValues!.isApprovePatientStepCompleted,
        isSendIWelcomeStepCompleted:
          step == "send-i-welcome"
            ? newValue
            : defaultValues!.isSendIWelcomeStepCompleted,
        isPraktijkdataActionsStepCompleted:
          step == "praktijkdata-actions"
            ? newValue
            : defaultValues!.isPraktijkdataActionsStepCompleted,
      },
    });
  };

  return (
    <Checkbox
      disabled={isLoading || isFetching || isSaving}
      checked={value}
      onCheckedChange={() => onChangeValue()}
    />
  );
};
