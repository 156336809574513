import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../../types";
import { useTranslation } from "react-i18next";
import { AlertIndicator } from "../../alerts/alertIndicator";

export const AlertsColName = "Alerts";

export const AlertsCol: ColumnDef<MainListPatient> = {
  id: AlertsColName,
  accessorKey: "alerts",
  size: 100,
  minSize: 10,
  header: () => {
    const { t } = useTranslation();

    return (
      <p className="text-center">{t("case-load-manager-column-alerts")}</p>
    );
  },
  cell: ({ row: { original } }) => {
    return (
      <div className="py-2 px-4">
        <AlertIndicator data={original} showLabel={false} />
      </div>
    );
  },
};
