import { Loader2, UserPlus, UsersRound } from "lucide-react";
import { Button } from "../../../../../../../components/ui/button";
import { Skeleton } from "../../../../../../../components/ui/skeleton";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../../../../components/ui/dialog";
import { useState } from "react";
import { CaseLoadChatConversationDetails } from "../../../../../types";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
} from "../../../../../../../components/ui/command";
import { useGetUsersToAddToChannel } from "../../../../hooks";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../components/ui/popover";
import { SelectableUserToAddToChannel } from "./selectableUserToAddToChannel";
import { UserOfChannel } from "./userOfChannel";

export const ParticipantsButton = ({
  isFetchingChatDetails,
  chatDetails,
}: {
  isFetchingChatDetails: boolean;
  chatDetails: CaseLoadChatConversationDetails;
}) => {
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isSearchUsersComboboxOpen, setIsSearchUsersComboboxOpen] =
    useState(false);

  const { data: selectableUsers, isLoading: isLoadingUsers } =
    useGetUsersToAddToChannel(chatDetails.details!.channelId);

  return (
    <>
      <Button
        variant="outline"
        className="flex items-center space-x-2"
        onClick={() => setIsDetailsDialogOpen(true)}
      >
        <div className="w-4 h-4">
          <UsersRound className="w-4 h-4" />
        </div>
        <p>Participants</p>
      </Button>

      <Dialog
        open={isDetailsDialogOpen}
        onOpenChange={(newValue) => {
          setIsDetailsDialogOpen(newValue);
        }}
      >
        <DialogContent className="p-8">
          <DialogHeader>
            <DialogTitle className="text-xl">Participants</DialogTitle>
            <DialogDescription>
              <p>View and edit who has access to this chat.</p>
            </DialogDescription>
          </DialogHeader>

          {isFetchingChatDetails ? (
            <div className="space-y-2">
              <Skeleton className="h-8 w-full rounded" />
              <Skeleton className="h-8 w-full rounded" />
              <Skeleton className="h-8 w-full rounded" />
            </div>
          ) : (
            <ul className="space-y-2 my-2">
              {chatDetails
                .details!.members.filter((m) => m.userId != "iPracticeChatBot")
                .map((m) => {
                  return (
                    <UserOfChannel
                      key={m.userId}
                      channelId={chatDetails.details!.channelId}
                      user={m}
                    />
                  );
                })}
            </ul>
          )}

          <div className="flex items-center justify-between w-full pt-2">
            <Popover
              open={isSearchUsersComboboxOpen}
              onOpenChange={setIsSearchUsersComboboxOpen}
            >
              <PopoverTrigger asChild>
                <Button
                  role="combobox"
                  className="px-4 flex items-center space-x-2"
                >
                  <div className="w-4 h-4">
                    <UserPlus className="w-4 h-4" />
                  </div>
                  <p>Add new user</p>
                </Button>
              </PopoverTrigger>
              <PopoverContent
                side="bottom"
                align="start"
                className="w-[400px] z-[9999999] p-0"
              >
                <Command
                  filter={(value, search) => {
                    return value.toLowerCase().includes(search.toLowerCase())
                      ? 1
                      : 0;
                  }}
                >
                  <CommandInput placeholder="Search for users..." autoFocus />
                  <CommandList>
                    <CommandEmpty className="italic p-2 opacity-50">
                      No users found.
                    </CommandEmpty>

                    <CommandGroup className="bg-slate-50/50 min-h-fit max-h-[12rem] overflow-y-scroll z-[99999999]">
                      {isLoadingUsers ? (
                        <div className="flex items-center space-x-2">
                          <div className="w-4 h-4">
                            <Loader2 className="w-4 h-4" />
                          </div>
                          <p className="italic">Loading...</p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {(selectableUsers ?? []).map((user) => (
                        <SelectableUserToAddToChannel
                          key={user.email}
                          channelId={chatDetails.details!.channelId}
                          user={user}
                          onClose={() => setIsSearchUsersComboboxOpen(false)}
                        />
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>

            <Button
              variant="outline"
              onClick={() => setIsDetailsDialogOpen(false)}
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
