import { Phone } from "lucide-react";
import { useState } from "react";
import { VideoCall } from "./videocall";

export const VideoCallButton = ({
  chatChannelId,
}: {
  chatChannelId: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="bg-[#0ba5ec] text-white py-2 rounded-lg px-4 cursor-pointer w-14 flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <Phone className="h-5 w-5" />
      </div>
      {isOpen && (
        <VideoCall
          chatChannelId={chatChannelId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};
