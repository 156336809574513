import { Dialog, DialogContent } from "../../../../../../components/ui/dialog";
import { useEffect, useState } from "react";
import "stream-chat-react/dist/css/v2/index.css";
import {
  CallingState,
  Call,
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  useCall,
  useCallStateHooks,
  User,
  StreamTheme,
  ParticipantView,
  StreamVideoParticipant,
  CallControls,
} from "@stream-io/video-react-sdk";
import "@stream-io/video-react-sdk/dist/css/styles.css";
import { useGetChatCredentials } from "../../../hooks";
import { useIsEnabled } from "../../../../../../feature-management/useIsEnabled";

export const VideoCall = ({
  chatChannelId,
  isOpen,
  setIsOpen,
}: {
  chatChannelId: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );
  const { data: chatCredentials } = useGetChatCredentials(
    isCaseLoadManagerWireReplacementEnabled ?? false
  );

  const [videoClient, setVideoClient] = useState<StreamVideoClient | null>(
    null
  );

  const apiKey =
    process.env.REACT_APP_STREAM_CHAT_API_KEY ?? "should-not-happen";

  const [call, setCall] = useState<Call | null>(null);

  const initVideo = async () => {
    if (call) return;

    // todo: get from backend
    const user: User = {
      id: chatCredentials!.userId,
      name: chatCredentials!.userName,
      image: "https://getstream.imgix.net/images/random_svg/A.svg",
    };

    const client = StreamVideoClient.getOrCreateInstance({
      apiKey,
      user,
      token: chatCredentials!.token,
    });

    setVideoClient(client);
  };

  useEffect(() => {
    if (!chatCredentials) return;

    initVideo();

    return () => {
      call?.leave();
    };
  }, [chatCredentials]);

  const renderDialog = () => {
    if (videoClient == null) {
      return (
        <div className="flex items-center justify-center min-h-full">
          Loading...
        </div>
      );
    }

    if (call == null) {
      const newCall = videoClient.call("default", chatChannelId);

      newCall.join({
        create: true,
        data: {
          custom: { color: "blue" },
        },
      });

      setCall(newCall);

      return (
        <div className="flex items-center justify-center min-h-full">
          Loading...
        </div>
      );
    }

    return (
      <StreamTheme>
        <StreamVideo client={videoClient}>
          <StreamCall call={call}>
            <MyUILayout />
          </StreamCall>
        </StreamVideo>
      </StreamTheme>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(newValue) => {
        setIsOpen(newValue);
      }}
    >
      <DialogContent className="p-8 min-w-[95vw] max-h-[95vh]">
        {renderDialog()}
      </DialogContent>
    </Dialog>
  );
};

export const MyUILayout = () => {
  const call = useCall();

  const { useCallCallingState, useLocalParticipant, useRemoteParticipants } =
    useCallStateHooks();

  const callingState = useCallCallingState();
  const remoteParticipants = useRemoteParticipants();

  const removeDuplicatesByUserId = (
    data: StreamVideoParticipant[]
  ): StreamVideoParticipant[] => {
    const uniqueObjects: StreamVideoParticipant[] = [];
    const seenIds: Set<string> = new Set();

    for (const obj of data) {
      const id = obj.userId;
      if (!seenIds.has(id)) {
        uniqueObjects.push(obj);
        seenIds.add(id);
      }
    }

    return uniqueObjects;
  };

  if (
    callingState !== CallingState.JOINED ||
    !call ||
    removeDuplicatesByUserId(remoteParticipants).length === 0
  ) {
    return (
      <div className="min-h-full min-w-full flex items-center justify-center text-5xl font-bold text-slate-300">
        Loading...
      </div>
    );
  }

  return (
    <main className="min-h-[85vh]">
      <StreamTheme>
        <MyParticipantList
          participants={removeDuplicatesByUserId(remoteParticipants)}
        />
        <CallControls />
      </StreamTheme>
    </main>
  );
};

// todo: do we need this?
export const MyParticipantList = (props: {
  participants: StreamVideoParticipant[];
}) => {
  const { participants } = props;

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
      {participants.map((participant) => (
        <ParticipantView
          participant={participant}
          key={participant.sessionId}
        />
      ))}
    </div>
  );
};

// todo: do we need this?
export const MyFloatingLocalParticipant = (props: {
  participant?: StreamVideoParticipant;
}) => {
  const { participant } = props;

  if (!participant) return <></>;
  return (
    <div
      style={{
        position: "absolute",
        top: "15px",
        left: "15px",
        width: "240px",
        height: "135px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px 3px",
        borderRadius: "12px",
      }}
    >
      <ParticipantView participant={participant} />
    </div>
  );
};
