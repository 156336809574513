import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../../types";
import { useTranslation } from "react-i18next";

export const RemarksColName = "Remarks";

export const RemarksCol: ColumnDef<MainListPatient> = {
  id: RemarksColName,
  accessorKey: "remarks",
  size: 180,
  minSize: 10,
  header: () => {
    const { t } = useTranslation();

    return <p>{t("case-load-manager-column-remarks")}</p>;
  },
  cell: ({ row }) => {
    return (
      <div className="flex justify-center items-start gap-2 py-2 px-4">
        {row.original.remarks.length > 0 && (
          <ul className="list-none space-y-2 text-xs">
            {row.original.remarks.map((r) => {
              return (
                <li className="whitespace-pre-wrap break-all" key={r}>
                  {r}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  },
};
