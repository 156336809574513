import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectGroup,
  SelectValue,
} from "../../../../components/ui/select";
import { useGetLocationWithTeamTags } from "../../hooks";
import { PreIntakeReportTeamAndLocationFilter } from "../../types";

export const SchedulePreIntakeLocationAndTeamFilter = ({
  isLoadingCaseLoads,
  intakeLocation,
  selectedLocationAndTeam,
  setSelectedLocationAndTeam,
}: {
  isLoadingCaseLoads: boolean;
  intakeLocation: string;
  selectedLocationAndTeam: PreIntakeReportTeamAndLocationFilter | null;
  setSelectedLocationAndTeam: React.Dispatch<
    React.SetStateAction<PreIntakeReportTeamAndLocationFilter | null>
  >;
}) => {
  const {
    data: selectableLocationAndTeams,
    isLoading: isLoadingLocationWithTeamTags,
  } = useGetLocationWithTeamTags((data) => {
    setDefaultLocationAndTeam(data);
  });

  useEffect(() => {
    setDefaultLocationAndTeam(selectableLocationAndTeams ?? []);
  }, [intakeLocation]);

  const setDefaultLocationAndTeam = (
    data: PreIntakeReportTeamAndLocationFilter[]
  ) => {
    if (intakeLocation.startsWith("Amsterdam")) {
      const amsterdam = data.filter((x) => x.locationName === "Amsterdam")[0];
      setSelectedLocationAndTeam(amsterdam);
    } else {
      const location = data.filter((x) => x.locationName === intakeLocation)[0];
      setSelectedLocationAndTeam(location);
    }
  };

  const getDuplicateStrings = (strings: string[]): string[] => {
    const frequencyMap: Record<string, number> = {};

    for (const str of strings) {
      frequencyMap[str] = (frequencyMap[str] || 0) + 1;
    }

    return strings.filter((str) => frequencyMap[str] > 1);
  };

  const renderSelectableLocationsAndTeams = () => {
    if (!selectableLocationAndTeams) return <></>;

    const locationsWithMultipleTeams = getDuplicateStrings(
      selectableLocationAndTeams?.map((x) => x.locationName)
    );

    return (
      <>
        {selectableLocationAndTeams
          .toSorted((a, b) => a.locationName.localeCompare(b.locationName))
          .map((location) => {
            const key = location.locationName + ";" + location.teamId;
            let label = location.locationName;

            if (locationsWithMultipleTeams?.includes(location.locationName)) {
              label = location.locationName + " " + location.teamId;
            }

            return (
              <SelectItem key={key} value={key}>
                {label}
              </SelectItem>
            );
          })}
      </>
    );
  };

  return (
    <Select
      onValueChange={(value) => {
        const splitValues = value.split(";");
        const location = splitValues[0];
        const team = splitValues[1];

        setSelectedLocationAndTeam({
          locationName: location,
          teamId: team ? Number(team) : 0,
        });
      }}
      disabled={isLoadingCaseLoads || isLoadingLocationWithTeamTags}
      value={
        selectedLocationAndTeam
          ? selectedLocationAndTeam.locationName +
            ";" +
            selectedLocationAndTeam.teamId
          : ""
      }
    >
      <SelectTrigger className="w-1/3">
        <SelectValue placeholder="Select a location..." />
      </SelectTrigger>
      <SelectContent className={`z-[9999999] max-h-[30vh]`}>
        <SelectGroup>
          <SelectItem value="all" className="italic">
            All locations
          </SelectItem>
          {renderSelectableLocationsAndTeams()}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
