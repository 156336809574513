import { CrmNoChangeInDaysReportResult } from "../../../types";
import { colors, getReadableLabelFromDataKey } from "./utils";
import { getSimpleFormattedDate } from "../../../../caseLoadCockpit/utils";

export const CustomTooltip = ({
  active,
  payload,
  label,
}: {
  active: boolean;
  label: string;
  payload: {
    name: string;
    dataKey: Exclude<keyof CrmNoChangeInDaysReportResult, "date">;
    color: string;
    value: number;
  }[];
}) => {
  const dataKeys: Exclude<keyof CrmNoChangeInDaysReportResult, "date">[] = [
    "countOfZeroDays",
    "countOfOneDay",
    "countOfTwoToSevenDays",
    "countOfEightToFourteenDays",
    "countOfMoreThanFourteenDays",
  ];

  if (active && payload && payload.length > 0) {
    return (
      <div className="p-4 rounded shadow text-base min-w-[240px] bg-slate-900 text-slate-300">
        <h2 className="pb-1 font-bold text-slate-200">
          {getSimpleFormattedDate(label)}
        </h2>

        {dataKeys.map((dk) => {
          return (
            <div className="flex items-center space-x-2" key={dk}>
              {/* tailwind's bg-[**magic value**] doesn't work here */}
              <div
                className="w-2 h-2 rounded-full"
                style={{ backgroundColor: colors[dk] }}
              />
              <p>
                {getReadableLabelFromDataKey(dk)}:{" "}
                <span className="pl-1 font-bold">
                  {payload.find((x: any) => x.dataKey === dk)?.value ?? 0}
                </span>
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};
