import { TFunction } from "i18next";
import { AlertCode } from "../../types";

export const getReadableAlertText = (
  t: TFunction<"translation", undefined>,
  alertCode: AlertCode
) => {
  switch (alertCode) {
    case "NoRegularAppointmentsInLastMonth":
      return t("case-load-manager-alert-no-regular-appointment");
    case "NoChatAppointmentsInLastMonth":
      return t("case-load-manager-alert-no-chat-appointment");
    case "ChatAppointmentCreatedOutsideBusinessHours":
      return t("case-load-manager-alert-chat-appointment-outside-hours");
    default:
      return t("case-load-manager-alert-unknown");
  }
};
