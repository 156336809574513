import { SchedulePreIntakeViaCaseLoadData } from "../../customerRelationshipManager/referralDetails/praktijkdata/schedulePreIntakeViaCaseLoadData";

export default function PreIntakeReport() {
  return (
    <main className="p-24">
      <section>
        <h1 className="text-4xl font-bold">Pre-Intake Report</h1>
        <p>
          Find detailed information about each OP and their upcoming
          pre-intakes.
        </p>
      </section>

      <section className="mt-4 mb-6 p-4 inline-block bg-slate-100 rounded text-slate-500 w-1/2">
        <p>
          Max caseload is 80-85 cliënten voor een 32uur en 90-95 voor 36uur.
          Check altijd MAX aantal caseloads per week die wij aangeven
        </p>
      </section>

      <SchedulePreIntakeViaCaseLoadData
        setSelectedPsychologistEpdId={() => {}}
        intakeLocation={""}
        asCard={false}
      />
    </main>
  );
}
