import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CaseLoadOverview } from "./caseLoadOverview/overview";
import { ProgressChart } from "./progress/chart";
import { AbsenceHeader } from "./caseLoadOverview/absence/absenceHeader";
import { AlertHeader } from "./caseLoadOverview/alerts/alertHeader";
import { CaseLoadAlert } from "./types";
import {
  getCurrentMondayAndFriday,
  getOnlyCaseLoadEntriesWithAlertsFromLocalStorage,
} from "./utils";
import { NavBarOpenStateContext } from "../../layouts/NavBar/NavBarOpenStateContext";
import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
  TabContext,
} from "../../components/ui/horizontalTab";
import { CaseloadStatistics } from "./userStatistics/caseloadStatistics";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { OnboardPatients } from "./onboardPatients";
import { useGetOnboardingPatients } from "./onboardPatients/hooks";
import { DefaultGenerics, StreamChat } from "stream-chat";
import { useGetChatCredentials } from "./caseLoadOverview/hooks";
import { useIsEnabled } from "../../feature-management/useIsEnabled";

const CaseLoadCockpit = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { isNavBarOpen } = useContext(NavBarOpenStateContext)!;

  const [alerts, setAlerts] = useState<
    {
      caseLoadEntryId: string;
      alerts: CaseLoadAlert[];
    }[]
  >([]);

  const [absence, setAbsence] = useState<{
    startDate: string;
    endDate: string;
  } | null>(null);

  const [onlyCaseLoadsWithAlert, setOnlyCaseLoadsWithAlert] = useState(
    getOnlyCaseLoadEntriesWithAlertsFromLocalStorage()
  );

  const { monday } = getCurrentMondayAndFriday();

  const getDefaultSelectedDate = () => {
    const fromUrl = searchParams.get("period");
    return fromUrl ? new Date(fromUrl!) : monday;
  };

  const [date, setDate] = useState<Date>(getDefaultSelectedDate());

  const getWrapperClass = () => {
    const isOldDesign = localStorage.getItem("force-old-design") == "true";
    if (!isOldDesign) return "";

    return isNavBarOpen ? "-ml-12" : "ml-0";
  };

  return (
    <main className={`px-6 ${getWrapperClass()}`}>
      <AbsenceHeader absence={absence} />
      <AlertHeader
        hasAbsence={absence != null}
        alerts={alerts}
        onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
        setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
      />

      <div className="mt-24 relative w-full">
        <div className="pt-2">
          <HorizontalTab>
            <Tabs
              setAlerts={setAlerts}
              setAbsence={setAbsence}
              date={date}
              setDate={setDate}
              onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
              setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
            />
          </HorizontalTab>
        </div>

        <div className="z-0 absolute top-0 -mt-[70px] w-full flex justify-end">
          <ProgressChart date={date} />
        </div>
      </div>
    </main>
  );
};

const Tabs = ({
  setAlerts,
  setAbsence,
  date,
  setDate,
  onlyCaseLoadsWithAlert,
  setOnlyCaseLoadsWithAlert,
}: {
  setAlerts: Dispatch<
    SetStateAction<
      {
        caseLoadEntryId: string;
        alerts: CaseLoadAlert[];
      }[]
    >
  >;
  setAbsence: Dispatch<
    SetStateAction<{
      startDate: string;
      endDate: string;
    } | null>
  >;
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  onlyCaseLoadsWithAlert: boolean;
  setOnlyCaseLoadsWithAlert: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { setActiveTab } = useContext(TabContext)!;
  const searchParams = new URLSearchParams(location.search);

  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );

  const history = useHistory();

  useEffect(() => {
    if (location.search.includes("viewAs=onboarding")) setActiveTab(1);
    else if (location.search.includes("viewAs=statistics")) setActiveTab(2);
    else setActiveTab(0);
  }, [location.search]);

  const { data: onboardingPatients } = useGetOnboardingPatients();
  const { monday } = getCurrentMondayAndFriday();

  const countOfOnboardingPatients =
    onboardingPatients?.data?.filter(
      (x) => !x.treatmentStartStandardMessageSent
    )?.length ?? 0;

  const onChangeDate = (newDate: Date) => {
    const parsedDate = moment(newDate).utc(true).toDate();

    if (parsedDate.getDate() == monday.getDate()) {
      searchParams.delete("period"); // cleaner url, if we delete, rather than put this week's monday there
    } else {
      searchParams.set("period", parsedDate.toISOString());
    }

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    setDate(parsedDate);
  };

  const apiKey =
    process.env.REACT_APP_STREAM_CHAT_API_KEY ?? "should-not-happen";

  const { data: chatCredentials } = useGetChatCredentials(
    isCaseLoadManagerWireReplacementEnabled ?? false
  );

  const [chatClient, setChatClient] =
    useState<StreamChat<DefaultGenerics> | null>(null);

  const [couldNotLoadChat, setCouldNotLoadChat] = useState(false);

  useEffect(() => {
    const initChat = async () => {
      try {
        const client = StreamChat.getInstance(apiKey);

        await client.connectUser(
          { id: chatCredentials!.userId },
          chatCredentials!.token
        );
        setChatClient(client);
      } catch (error) {
        setCouldNotLoadChat(true);
      }
    };

    if (
      isCaseLoadManagerWireReplacementEnabled &&
      !chatClient &&
      chatCredentials?.token != null
    ) {
      initChat();
    }
  }, [chatCredentials, isCaseLoadManagerWireReplacementEnabled]);

  return (
    <div className="w-full mb-12">
      <div className="flex items-center ml-3">
        <HorizontalTabHeader
          index={0}
          onOverrideChangeTab={() => {
            history.push({
              pathname: location.pathname,
              search: "",
            });
          }}
        >
          {t("case-load-manager-my-case-load-tab-title")}
        </HorizontalTabHeader>
        <HorizontalTabHeader
          index={1}
          onOverrideChangeTab={() => {
            // reset date searchParam
            onChangeDate(new Date());

            history.push({
              pathname: location.pathname,
              search:
                "?" + new URLSearchParams({ viewAs: "onboarding" }).toString(),
            });
          }}
        >
          <>
            <p>{t("case-load-manager-onboarding-tab-title")}</p>

            <div
              className={`h-4 bg-ipractice-blue absolute top-0 right-[20px] text-xs text-white rounded-full flex items-center justify-center ${
                countOfOnboardingPatients && countOfOnboardingPatients > 0
                  ? "visible"
                  : "hidden"
              } ${countOfOnboardingPatients > 10 ? "w-5" : "w-4"}`}
            >
              {countOfOnboardingPatients}
            </div>
          </>
        </HorizontalTabHeader>

        <HorizontalTabHeader
          index={2}
          onOverrideChangeTab={() => {
            // reset date searchParam
            onChangeDate(new Date());
            history.push({
              pathname: location.pathname,
              search:
                "?" + new URLSearchParams({ viewAs: "statistics" }).toString(),
            });
          }}
        >
          {t("case-load-manager-statistics-tab-title")}
        </HorizontalTabHeader>
      </div>
      <HorizontalTabContent index={0}>
        <CaseLoadOverview
          date={date}
          onChangeDate={onChangeDate}
          setAbsence={setAbsence}
          setAlerts={setAlerts}
          onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
          setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
          chatClient={chatClient}
          couldNotLoadChat={couldNotLoadChat}
        />
      </HorizontalTabContent>
      <HorizontalTabContent index={1}>
        <OnboardPatients chatClient={chatClient} />
      </HorizontalTabContent>
      <HorizontalTabContent index={2}>
        <div className="p-4">
          <CaseloadStatistics />
        </div>
      </HorizontalTabContent>
    </div>
  );
};

export default CaseLoadCockpit;
