import { KopModelFields, KopModelStatus } from "./onboardPatients/types";

export type MainListPatient = {
  id: string;
  patientId: string;
  epdPatientId: number;
  epdFileId: number;
  dossierLookupGuid: string;
  dossierId: number;
  dossierType: string | null;
  period: { week: number; year: number };
  name: string;
  email: string;
  wireUserName: string | null;
  phoneNumber: string | null;
  hasChattedWith: boolean;
  patientSupportType: PatientSupportType;
  setAsHasChattedWithOn?: string;
  remarks: string[];
  currentWeekCommentId: string | null;
  psychologistComment: string | null;
  isCheckinNeeded: boolean;
  justificationIfCheckinNotNeeded: string | null;
  intakeOn?: string;
  lastRegularConsultOn?: string;
  nextRegularConsultOn?: string;
  lastChatConsultOn?: string;
  numberOfRegularConsults: number;
  numberOfVideoCallConsults: number;
  hasVideoCallConsultThisWeek: boolean;
  nameOfConsultationRoomPsychologists: string[];
  isPatientInsured: boolean;
  isPatientContracted: boolean;
  hasBeenChargedAlready: boolean;
  treatment: string;
  epdTreatment: {
    treatmentPlan: string | null;
    aiTreatmentPlanSummary: string | null;
  };
  alerts: CaseLoadAlert[];
  automaticBillingScheduledOn: string | null;
  isAutomaticBillOutsideWorkingHours: boolean | null;
  isSoonToBeDischarged: boolean;
  offboardingData: {
    id: string;
    treatmentSummary: string;
    sendRequestToGoogleReviewToPatient: boolean;
    hasBeenProcessedAfterLastConsultationRoomSession: boolean;
    hasSentTreatmentSummaryToPraktijkData: boolean;
    hasSentLastWireMessageToPatient: boolean;
    hasSentWireConversationExportToPraktijkdata: boolean;
  } | null;
};

export type PatientDetails = MainListPatient & {
  kopModel:
    | (KopModelFields & {
        status: KopModelStatus;
      })
    | null;
};

export type CaseLoadAlert = { code: AlertCode; hasMarkedAsCompleted: boolean };

export type CaseLoadMainListMetadata = {
  alerts: {
    caseLoadEntryId: string;
    alerts: CaseLoadAlert[];
  }[];
  absence: { startDate: string; endDate: string } | null;
};

export type AlertCode =
  | "NoRegularAppointmentsInLastMonth"
  | "NoChatAppointmentsInLastMonth"
  | "ChatAppointmentCreatedOutsideBusinessHours"
  | "DossierNoLongerActive";

export type SelectablePatient = {
  email: string;
  name: string;
  patientId: string;
};

export type AutomaticBilling = {
  id: string;
  patientId: string;
  epdPatientId: number;
  period: { week: number; year: number };
  name: string;
  email: string;
  appointment: string;
  generatedOn: string;
  generatedBy: string;
};

export enum CaseLoadSortingColumns {
  Patient,
  Dossier,
  Status,
  IntakeOn,
  LastRegularConsultOn,
  NextRegularConsultOn,
  LastChatConsultOn,
  CheckinNeeded,
}

export enum AutomaticBillingSortingColumns {
  Patient,
  Appointment,
  GeneratedOn,
}

export type SelectableEpdAppointmentType = {
  id: string;
  epdId: number;
  name: string;
};

export enum AppointmentConfigArea {
  PreIntake = "PreIntake",
  F2fIntake = "F2fIntake",
  RegularConsult = "RegularConsult",
  ChatConsult = "ChatConsult",
  VideoCall = "VideoCall",
}

export type CurrentAppointmentPerCaseLoadAreaConfig = {
  area: AppointmentConfigArea;
  appointmentType: SelectableEpdAppointmentType;
};

export type CurrentAppointmentPerDossierTypeConfig = {
  id: string;
  epdAppointmentType: {
    id: string;
    name: string;
    epdId: number;
  } | null;
  epdDossierType: {
    id: string;
    name: string;
    epdId: number;
  };
};

export type CaseLoadCurrentWeekProgressChart = {
  totalNumberOfPatients: number;
  totalNumberOfExcludedPatients: number;
  days: {
    day: string;
    count: number;
  }[];
};

export type CaseLoadWeekByWeekProgressChart = {
  year: number;
  week: number;
  period: string;
  totalNumberOfPatients: number;
  totalNumberOfExcludedPatients: number;
  totalNumberOfSupportedPatients: number;
  praktijkdataAppointments: number;
};

export type CaseLoadDetailedDataOverview = {
  psychologist: string;
  numberOfChatAppointmentsCreated: number;
  totalPatients: number;
  checkins: number;
};

export type CaseLoadDetailedDataFull = {
  psychologist: string;
  patient: string;
  needsCheckin: boolean;
  hasCreatedChatAppointment: boolean;
  reasonForNoCheckin: string | null;
  hasChattedWith: boolean;
};

export type AutomaticBillHistory = {
  psychologist: string;
  patient: string;
  period: {
    year: number;
    week: number;
  };
  billCreatedOn: string;
  appointmentOn: string;
  createdBy: string;
};

export type OpToCrpPsychologistRatioType = {
  psychologist: string;
  patient: string;
  numberOfChatAppointments: number;
  numberOfConsultationRoomAppointments: number;
};

export type PatientSupportType = null | "Chat" | "VideoCall" | "Both";

export type CaseLoadChatConversationDetails = {
  dossierLookupGuid: string;
  details: null | {
    channelId: string;
    members: UserCurrentInChannel[];
    channelCreationDate: string;
    numberOfUnreadMessages: number;
  };
};

export type UserCurrentInChannel = {
  userId: string;
  userName: string;
};

export type UserToAddToChannel = {
  email: string;
  userName: string;
};
