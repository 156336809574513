import { Loader2, X } from "lucide-react";

import { UserCurrentInChannel } from "../../../../../types";

import { useRemoveUserFromChat } from "../../../../hooks";
import { toast } from "sonner";

export const UserOfChannel = ({
  channelId,
  user,
}: {
  channelId: string;
  user: UserCurrentInChannel;
}) => {
  const { mutate: removeUserFromChat, isLoading: isLoadingRemoveUserFromChat } =
    useRemoveUserFromChat(() => {
      toast("User removed from chat", {
        description: "The user has been removed from the chat",
      });
    });

  return (
    <li className="p-2 rounded border flex items-center justify-between">
      <p className="font-bold">{user.userName}</p>
      <div
        className="w-4 h-4 opacity-75 hover:opacity-100 cursor-pointer"
        onClick={() =>
          removeUserFromChat({
            channelId,
            userId: user.userId,
          })
        }
      >
        {isLoadingRemoveUserFromChat ? (
          <Loader2 className="w-4 h-4" />
        ) : (
          <X className="w-4 h-4" />
        )}
      </div>
    </li>
  );
};
